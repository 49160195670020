<template>
  <div class="promise-temp"></div>
</template>

<script>
import { getPromiseBacklogById } from "@/api/psm";
export default {
  name: "PromiseTemp",
  props: ["id"],
  data() {
    return {
      timer: null
    };
  },
  created() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true
    });
    if (this.id) {
      this.resolveRouter(this.id);
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$router.back();
      }, 500);
    }
  },
  beforeDestroy() {
    this.$toast.clear();
    clearTimeout(this.timer);
  },
  methods: {
    async resolveRouter(id) {
      try {
        const resolveValue = await getPromiseBacklogById(id);
        console.log(resolveValue);
        // 填报是true 详情false
        if (resolveValue) {
          await this.$router.replace({ name: "PromiseFill", params: { id } });
        } else {
          await this.$router.replace({
            name: "PromiseDetails",
            params: { id }
          });
        }
      } catch (e) {
        console.log(e);
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$router.back();
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
.promise-temp {
  width: 100%;
  height: 100%;
}
</style>
